import React from 'react';
import Icons from '../../../../theme/icons';
import './styles.scss';
import {Tooltip} from 'antd';
function ProcedureCards({title, description, status, onClick}) {
  let bgColor =
    status == 'Not Started'
      ? '#F8D7DA'
      : status == 'Incomplete'
      ? '#FFF3CD'
      : '#D4EDDA';
  let textColor =
    status == 'Not Started'
      ? '#721C24'
      : status == 'Incomplete'
      ? '#856404'
      : '#155724';
  const statusIndicator = () => {
    if (status == '') return null;
    return (
      <div
        style={{backgroundColor: bgColor, color: textColor}}
        className="w-max p-1  rounded-lg text-[10px] whitespace-nowrap">
        {status}
      </div>
    );
  };

  return (
    <div className="relative card-wrapper p-[15px] border-[1px]  border-solid border-grey-200 rounded-[3px] min-h-[175px] h-full  hover:border-blue-200">
      <div className="flex items-start justify-start gap-[10px] mb-[20px]">
        <Icons.ProcedureIcon width={30} height={30} />
        <p
          onClick={onClick}
          className="font-h4 text-blue-300 hover:text-blue-200 cursor-pointer ">
          {title}
        </p>
      </div>
      <Tooltip trigger={['hover']} title={description} placement="bottomLeft">
        <p className="font-paragraph mb-8 line-clamp-3">{description}</p>
      </Tooltip>
      <div className="absolute bottom-[15px] right-[15px] ">
        {statusIndicator()}
      </div>
    </div>
  );
}

export default ProcedureCards;
